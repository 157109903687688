<template>
  <div class="category-custom">
    <Breadcrumb :items="breadcrumbs" />

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <CategoryTitle :category="category" />
    <v-container>
      <CategoryMainSlider
        :categories="category.children"
        :key="category.categoryId"
        paginationClass="subcategory"
      />
    </v-container>
    <v-container :fluid="$vuetify.breakpoint.xsOnly">
      <h3>Tutti i <strong>prodotti</strong></h3>

      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        @productsCount="updateCount"
        :hidePromoFilter="true"
        hideFilterString="Main Term"
        :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')"
        :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
        :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_TEXT"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.category-custom {
  .center-img {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";

import get from "lodash/get";

export default {
  name: "CategoryCustom",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      count: -1
    };
  },
  components: {
    CategoryTitle,
    CategoryMainSlider,
    Breadcrumb,
    ProductListGrid
    // ,
    // CategoryBlock
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];
      let path = this.breadcrumb;
      if (path && path[0].to === "/" && path.length > 1) {
        return path;
      }
      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category ? this.category.name : ""
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      "Categoria: " + this.category ? this.category.name : ""
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "cooptrentino.it"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
  // async mounted() {

  // }
};
</script>
